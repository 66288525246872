// Native Javascript for Bootstrap 3 v2.0.27 | © dnp_theme | MIT-License
(function (root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD support:
    define([], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like:
    module.exports = factory();
  } else {
    // Browser globals (root is window)
    var bsn = factory();
    root.Dropdown = bsn.Dropdown;
  }
}(this, function () {

  /* Native Javascript for Bootstrap 3 | Internal Utility Functions
  ----------------------------------------------------------------*/
  "use strict";

  // globals
  var globalObject = typeof global !== 'undefined' ? global : this || window,
    DOC = document, // allow the library to be used in <head>

    // Native Javascript for Bootstrap Global Object
    BSN = globalObject.BSN = {},
    supports = BSN.supports = [],

    // function toggle attributes
    dataToggle = 'data-toggle',
    // components
    stringDropdown = 'Dropdown',
    
    // option keys
    target = 'target',

    // aria
    ariaExpanded = 'aria-expanded',

    // event names
    clickEvent = 'click',
    focusEvent = 'focus',
    keydownEvent = 'keydown',
    keyupEvent = 'keyup',
    
    // originalEvents
    showEvent = 'show',
    shownEvent = 'shown',
    hideEvent = 'hide',
    hiddenEvent = 'hidden',
    
    // other
    getAttribute = 'getAttribute',
    setAttribute = 'setAttribute',
    getElementsByTagName = 'getElementsByTagName',
    preventDefault = 'preventDefault',

    indexOf = 'indexOf',
    parentNode = 'parentNode',
    length = 'length',
    push = 'push',
    tabindex = 'tabindex',
    contains = 'contains',

    // set new focus element since 2.0.3
    setFocus = function (element) {
      element.focus ? element.focus() : element.setActive();
    },

    // class manipulation, since 2.0.0 requires polyfill.js
    addClass = function (element, classNAME) {
      element.classList.add(classNAME);
    },
    removeClass = function (element, classNAME) {
      element.classList.remove(classNAME);
    },
    hasClass = function (element, classNAME) { // since 2.0.0
      return element.classList[contains](classNAME);
    },

    // selection methods
    queryElement = function (selector, parent) {
      var lookUp = parent ? parent : DOC;
      return typeof selector === 'object' ? selector : lookUp.querySelector(selector);
    },
    getClosest = function (element, selector) { //element is the element and selector is for the closest parent element to find
      // source http://gomakethings.com/climbing-up-and-down-the-dom-tree-with-vanilla-javascript/
      var firstChar = selector.charAt(0), selectorSubstring = selector.substr(1);
      if (firstChar === '.') {// If selector is a class
        for (; element && element !== DOC; element = element[parentNode]) { // Get closest match
          if (queryElement(selector, element[parentNode]) !== null && hasClass(element, selectorSubstring)) { return element; }
        }
      } else if (firstChar === '#') { // If selector is an ID
        for (; element && element !== DOC; element = element[parentNode]) { // Get closest match
          if (element.id === selectorSubstring) { return element; }
        }
      }
      return false;
    },

    // event attach jQuery style / trigger  since 1.2.0
    on = function (element, event, handler) {
      element.addEventListener(event, handler, false);
    },
    off = function (element, event, handler) {
      element.removeEventListener(event, handler, false);
    },
    bootstrapCustomEvent = function (eventName, componentName, related) {
      var OriginalCustomEvent = new CustomEvent(eventName + '.bs.' + componentName);
      OriginalCustomEvent.relatedTarget = related;
      this.dispatchEvent(OriginalCustomEvent);
    };

  BSN.version = '2.0.27';

  /* Native Javascript for Bootstrap 3 | Dropdown
  ----------------------------------------------*/

  // DROPDOWN DEFINITION
  // ===================
  var Dropdown = function (element, option, selector) {

    // initialization element
    element = queryElement(element);

    // set option
    this.persist = option === true || element[getAttribute]('data-persist') === 'true' || false;
    this.selector = selector || element[getAttribute]('data-selector');
    
    // constants, event targets, strings
    var self = this, children = 'children',
      parent = element[parentNode],
      component = 'dropdown', open = 'open',
      relatedTarget = null,
      menu = queryElement('.dropdown-menu', parent),
      menuItems = (function () {
        var set = menu[children], newSet = [];
        for (var i = 0; i < set[length]; i++) {
          set[i][children][length] && (set[i][children][0].tagName === 'A' && newSet[push](set[i]));
        }
        return newSet;
      })(),

      // preventDefault on empty anchor links
      preventEmptyAnchor = function (anchor) {
        (anchor.href && anchor.href.slice(-1) === '#' || anchor[parentNode] && anchor[parentNode].href
          && anchor[parentNode].href.slice(-1) === '#') && this[preventDefault]();
      },

      // toggle dismissible events
      toggleDismiss = function () {
        var type = element[open] ? on : off;
        type(DOC, clickEvent, dismissHandler);
        type(DOC, keydownEvent, preventScroll);
        type(DOC, keyupEvent, keyHandler);
        type(DOC, focusEvent, dismissHandler, true);
      },

      // handlers
      dismissHandler = function (e) {
        var eventTarget = e[target], hasData = eventTarget && (eventTarget[getAttribute](dataToggle)
          || eventTarget[parentNode] && getAttribute in eventTarget[parentNode]
          && eventTarget[parentNode][getAttribute](dataToggle));
        if (e.type === focusEvent && (eventTarget === element || eventTarget === menu || menu[contains](eventTarget)) || self.selector && getClosest(e[target], self.selector)) {
          return;
        }
        if ((eventTarget === menu || menu[contains](eventTarget)) && (self.persist || hasData)) { return; }
        else {
          relatedTarget = eventTarget === element || element[contains](eventTarget) ? element : null;
          hide();
        }
        preventEmptyAnchor.call(e, eventTarget);
      },
      clickHandler = function (e) {
        relatedTarget = element;
        show();
        preventEmptyAnchor.call(e, e[target]);
      },
      preventScroll = function (e) {
        var key = e.which || e.keyCode;
        if (key === 38 || key === 40) { e[preventDefault](); }
      },
      keyHandler = function (e) {
        var key = e.which || e.keyCode,
          activeItem = DOC.activeElement,
          idx = menuItems[indexOf](activeItem[parentNode]),
          isSameElement = activeItem === element,
          isInsideMenu = menu[contains](activeItem),
          isMenuItem = activeItem[parentNode][parentNode] === menu;

        if (isMenuItem) { // navigate up | down
          idx = isSameElement ? 0
            : key === 38 ? (idx > 1 ? idx - 1 : 0)
              : key === 40 ? (idx < menuItems[length] - 1 ? idx + 1 : idx) : idx;
          menuItems[idx] && setFocus(menuItems[idx][children][0]);
        }
        if ((menuItems[length] && isMenuItem // menu has items
          || !menuItems[length] && (isInsideMenu || isSameElement)  // menu might be a form
          || !isInsideMenu) // or the focused element is not in the menu at all
          && element[open] && key === 27 // menu must be open
        ) {
          self.toggle();
          relatedTarget = null;
        }
      },

      // private methods
      show = function () {
        bootstrapCustomEvent.call(parent, showEvent, component, relatedTarget);
        addClass(parent, open);
        element[setAttribute](ariaExpanded, true);
        bootstrapCustomEvent.call(parent, shownEvent, component, relatedTarget);
        element[open] = true;
        off(element, clickEvent, clickHandler);
        setTimeout(function () {
          setFocus(menu[getElementsByTagName]('INPUT')[0] || element); // focus the first input item | element
          toggleDismiss();
        }, 1);
      },
      hide = function () {
        bootstrapCustomEvent.call(parent, hideEvent, component, relatedTarget);
        removeClass(parent, open);
        element[setAttribute](ariaExpanded, false);
        bootstrapCustomEvent.call(parent, hiddenEvent, component, relatedTarget);
        element[open] = false;
        toggleDismiss();
        setFocus(element);
        setTimeout(function () { on(element, clickEvent, clickHandler); }, 1);
      };

    // set initial state to closed
    element[open] = false;

    // public methods
    this.toggle = function () {
      if (hasClass(parent, open) && element[open]) { hide(); }
      else { show(); }
    };

    // init
    if (!(stringDropdown in element)) { // prevent adding event handlers twice
      !tabindex in menu && menu[setAttribute](tabindex, '0'); // Fix onblur on Chrome | Safari
      on(element, clickEvent, clickHandler);
    }

    element[stringDropdown] = self;
  };

  // DROPDOWN DATA API
  // =================
  supports[push]([stringDropdown, Dropdown, '[' + dataToggle + '="dropdown"]']);

  return {
    Dropdown: Dropdown
  };
}));
